import React, { useState, useEffect, useCallback } from 'react'; 
import PrintifyLogo from './PrintifyLogo';

const PRCForm = () => {
  const [productionCost, setProductionCost] = useState('');
  const [desiredProfit, setDesiredProfit] = useState('');
  const [shippingCost, setShippingCost] = useState('');
  const [additionalFees, setAdditionalFees] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [processingFeePercent, setProcessingFeePercent] = useState('');
  const [processingFeeCost, setProcessingFeeCost] = useState('');
  const [totalCost, setTotalCost] = useState('');

  const handleInputChange = (e, inputName) => {
    const value = e.target.value;

    switch(inputName) {
      case 'productionCost':
        setProductionCost(value);
        break;
      case 'desiredProfit':
        setDesiredProfit(value);
        break;
      case 'shippingCost':
        setShippingCost(value);
        break;
      default:
        console.log(`Unhandled input name: ${inputName}`);
    }
  };

  const calculateTotalCost = useCallback(() => {
    if (!productionCost.trim() || !desiredProfit.trim() || !shippingCost.trim()) {
      return;
    }

    const cost = parseFloat(productionCost) || 0;
    const profit = parseFloat(desiredProfit) || 0;
    const shipping = parseFloat(shippingCost) || 0;
    const fees = parseFloat(additionalFees) || 0;
    const subtotal = cost + fees + profit + shipping;

    let total;

    switch (paymentMethod) {
      case 'etsy':
        total = subtotal * 1.20;
        break;
      case 'square':
        total = subtotal * 1.026 + 0.10;
        break;
      case 'paypal':
        total = subtotal * 1.0299 + 0.49;
        break;
      case 'other':
        const processingFeePercentValue = parseFloat(processingFeePercent) || 0;
        const processingFeeCostValue = parseFloat(processingFeeCost) || 0;
        const processingFeeDecimal = processingFeePercentValue / 100;
        total = subtotal * (1 + processingFeeDecimal) + processingFeeCostValue;
        break;
      default:
        total = subtotal;
    }
    setTotalCost(total.toFixed(2));
  }, [productionCost, desiredProfit, shippingCost, additionalFees, paymentMethod, processingFeePercent, processingFeeCost]);

  useEffect(() => {
    calculateTotalCost();
  }, [calculateTotalCost]);

  return (
    <>
    <div className="prc">
      <div className="prc-header">
        <PrintifyLogo />
        <h1>React Calculator</h1>
      </div>
      <form className="prc-form">
        <div className="form-row">
          <label className="form-label" id="productionCostLabel" htmlFor="productionCost">Production Cost<sup>*</sup></label>
          <input value={productionCost} onChange={(e) => handleInputChange(e, 'productionCost')} className="form-input" type="number" id="productionCost" name="productionCost" placeholder="" aria-labelledby="productionCostLabel" />
        </div>
        <div className="form-row">
          <label className="form-label" id="desiredProfitLabel" htmlFor="profit">Desired Profit<sup>*</sup></label>
          <input value={desiredProfit} onChange={(e) => handleInputChange(e, 'desiredProfit')} className="form-input" type="number" id="profit" name="profit" placeholder="" aria-labelledby="desiredProfitLabel" />
        </div>
        <div className="form-row">
          <label className="form-label" id="shippingCostLabel" htmlFor="shippingCost">Shipping Cost<sup>*</sup></label>
          <input value={shippingCost} onChange={(e) => handleInputChange(e, 'shippingCost')} className="form-input" type="number" id="shippingCost" name="shippingCost" placeholder="" aria-labelledby="shippingCostLabel" />
        </div>
        <div className="form-row">
          <label className="form-label" id="additionalFeesLabel" htmlFor="additionalFees" aria-hidden="false">Additional Fees</label>
          <input value={additionalFees} onChange={(e) => setAdditionalFees(e.target.value)} className="form-input" type="input" id="additionalFees" name="additionalFees" data-type="input" placeholder="" aria-labelledby="additionalFeesLabel"/>
        </div>
        <div className="form-row payMethod">
          <label className="form-label" id="paymentMethodLabel" htmlFor="paymentMethod" aria-hidden="false">Payment Method</label>
          <select className="form-select paymentMethod" id="paymentMethod" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
            <option value="">Select a payment method</option>
            <option value="etsy">Etsy (~ 20%)</option>
            <option value="square">Square (2.6% + .10)</option>
            <option value="paypal">Paypal (2.9% + .49)</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className={`form-row otherFee ${paymentMethod === 'other'? 'otherFee-display' : ''}`}>
          <label className="form-label" id="processingFeeLabel" aria-hidden="false">Processing Fee <span>(Example: 2.99% + $0.10)</span></label>
          <div className="form-group">
            <div>
              <div className="input-group percent"><input className="form-input" type="input" id="processingFeePercent" aria-labelledby="processingFeeLabel" value={processingFeePercent} onChange={(e) => setProcessingFeePercent(e.target.value)} name="" data-type="" /></div>
            </div>
            <span>+</span>
            <div>
              <div className="input-group money"><input className="form-input" type="input" id="processingFeeCost" aria-labelledby="processingFeeLabel" value={processingFeeCost} onChange={(e) => setProcessingFeeCost(e.target.value)} name="" data-type="" /></div>
            </div>
          </div>
        </div>
        <div className="total-cost">
          <p><span>Suggested Retail Price:</span> <strong>{ totalCost }</strong></p>
        </div>

        <div className="form-button">
        </div>
        
      </form>
    </div>
  </>  
  );
}


export default PRCForm;
