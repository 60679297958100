import './App.css';
import PRCForm from './PRCForm';

function App() {
  return (
    <div className="prc-wrapper"> 
      <PRCForm />
    </div>
  );
}

export default App;
